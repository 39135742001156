import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_ROUTE;

const generateReqhashMAC = () => {
  // adjust HASH generation logic
  return "7xRJeTEBBjR6Lm1ExdITbafiol6fwlgh0owOZR0F9ow=";
};

/**
 * Makes an API call to get endpoint details.
 * 
 * @param {string} endpoint - The URL endpoint (example: "/terminal-details").
 * @param {Object} data - The body of the request.
 * @param {Object} [headers] - Additional headers for the request.
 * @param {boolean} [useDefaultHash] -Defines whether the default hash should be generated automatically.
 * @returns {Promise<Object>} - The data returned by the API.
 */
export const fetchData = async ({
  endpoint,
  data,
  headers = {},
  useDefaultHash = true,
}) => {
  const url = `${API_BASE_URL}${endpoint}`;

  const requestData = {
    ...data,
    ...(useDefaultHash && { reqhashMAC: generateReqhashMAC() }),
  };

  try {
    const response = await axios.post(url, requestData, { headers });
    return response;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    throw error; 
  }
};
