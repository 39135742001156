import { fetchData } from "../apiService";

export const getTerminalDetails = async (terminalID, token, acquirerData, searchFields) => {
  const endpoint = '/terminal-details';
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    acquirerName: acquirerData.acquirerName ,
    acquirerID: acquirerData.acquirerId ,
  };
  const data = {
    version: "04.09.01",
    acquirer: {
      merchantID: searchFields.merchant,
      terminalID: terminalID
    },
  };
  try {
    const response = await fetchData({ endpoint, data, headers });
    return response.data; 
  } catch (error) {
    console.error("Error fetching terminal details:", error);
    throw error;
  }
};
