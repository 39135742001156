import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, Paper, TextField, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AuthContext from "../context/AuthContext";
import SaveIcon from '@mui/icons-material/Save';
import { updateTerminal } from "../services/terminal/updateTerminalService";

const TerminalDetails = ({ terminalDetails, setSnackOpen, setMessageOpen, setSeverity }) => {
  const [formData, setFormData] = useState(terminalDetails || {});
  const { acquirerData, token } = useContext(AuthContext);
  const [isUpdated, setIsUpdated] = useState(false); 
  const [dialogOpen, setDialogOpen] = useState(false); 
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const keys = name.split(".");
      const updatedData = { ...prevData };
      let current = updatedData;

      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = current[keys[i]] || {};
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
      return updatedData;
    });
  };

  const handleSave = async () => {
    try {
      const dataTerminal = await updateTerminal(formData, token, acquirerData);
      setFormData(dataTerminal);
      setIsUpdated(true);

      setSnackOpen(true); 
      setMessageOpen("Data updated successfully!");
      setSeverity("success");

      setDialogOpen(true); 
    } catch (error) {
      console.error('Error saving data:', error);
      setSnackOpen(true);
      setMessageOpen("Error fetching terminal details");
      setSeverity("error");
    }
  };

  if (!terminalDetails) return null;

  const renderField = (label, value, name) => (
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value || ""}
        onChange={handleInputChange}
        variant="outlined"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  );
  const handleAddOwner = () => {
    setFormData((prevData) => ({
      ...prevData,
      owner: [...(prevData.owner || []), {
        first_name: "",
        last_name: "",
        middle_name: "",
        taxpayer_id: "",
        personal_email: "",
        personal_phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        neighborhood: "",
        percentage: "",
        residency_time: ""
      }]
    }));
  };
  
  const handleRemoveOwner = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      owner: prevData.owner.filter((_, i) => i !== index),
    }));
  };
  
  console.log(formData)
  return (
    <Box mt={4} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      {!isUpdated && (
        <>
          <Typography variant="h5" gutterBottom>
            Terminal Details
          </Typography>
          <Paper elevation={3} sx={{ padding: 3, overflowX: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Terminal Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField(
                  "Terminal ID",
                  formData.acquirer?.terminal?.terminals?.[0]?.terminalID,
                  "acquirer.terminal.terminals.0.terminalID"
                )}
                {renderField(
                  "Acquirer Terminal ID",
                  formData.acquirer?.terminal?.terminals?.[0]?.terminalSerial,
                  "acquirer.terminal.terminals.0.terminalSerial"
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Acquirer Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField(
                  "Acquirer ID",
                  acquirerData?.acquirerId,
                  "acquirerData.acquirerId"
                )}
                {renderField(
                  "Acquirer ISO ID",
                  formData.acquirer?.isoID,
                  "acquirer.isoID"
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Merchant Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField(
                  "Merchant Name",
                  formData.merchant?.legal_name,
                  "merchant.legal_name"
                )}
                {renderField(
                  "Business Email",
                  formData.merchant?.business_email,
                  "merchant.business_email"
                )}
                {renderField(
                  "Business Phone",
                  formData.merchant?.business_phone,
                  "merchant.business_phone"
                )}
                {renderField(
                  "Taxpayer ID",
                  formData.merchant?.taxpayer_id,
                  "merchant.taxpayer_id"
                )}
                {renderField(
                  "Incorporation Date",
                  formData.merchant?.incorporation_date,
                  "merchant.incorporation_date"
                )}
                {renderField(
                  "Effective Date",
                  formData.merchant?.effective_date,
                  "merchant.effective_date"
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Bank Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField("Bid", formData.bank?.bid, "bank.bid")}
                {renderField("SWIFT Code", formData.bank?.swift_code, "bank.swift_code")}
                {renderField("Account Number", formData.bank?.account_number, "bank.account_number")}
                {renderField("Bank Address", formData.bank?.address, "bank.address")}
                {renderField("Routing Number", formData.bank?.routing_number, "bank.routing_number")}
                {renderField("IBAN", formData.bank?.iban, "bank.iban")}
                {renderField("Branch Number", formData.bank?.branch_number, "bank.branch_number")}
                {renderField("City", formData.bank?.city, "bank.city")}
                {renderField("State", formData.bank?.state, "bank.state")}
                {renderField("Country Code", formData.bank?.country_code, "bank.country_code")}
                {renderField("Postal Code", formData.bank?.postal_code, "bank.postal_code")}
              </Grid>
              
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h5" gutterBottom>
                    Owner Details
                  </Typography>
                  <Tooltip title="Add New Owner">
                    <IconButton color="primary" onClick={handleAddOwner} size="large">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider />
              </Grid>
              {formData.owner?.map((owner, index) => (
                <Grid item xs={12} key={index}>
                  <Card elevation={3} sx={{ padding: 3 }}>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Owner {index + 1}</Typography>
                            <Tooltip title="Remove Owner" arrow>
                              <IconButton
                                color="error"
                                onClick={() => handleRemoveOwner(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Divider sx={{ marginY: 1 }} />
                        </Grid>
                        {renderField("First Name", owner.first_name, `owner.${index}.first_name`)}
                        {renderField("Last Name", owner.last_name, `owner.${index}.last_name`)}
                        {renderField("Middle Name", owner.middle_name, `owner.${index}.middle_name`)}
                        {renderField("ID", owner.id, `owner.${index}.id`)}
                        {renderField("Taxpayer ID", owner.taxpayer_id, `owner.${index}.taxpayer_id`)}
                        {renderField("Percentage", owner.percentage, `owner.${index}.percentage`)}
                        {renderField("ID Issuer", owner.id_issuer, `owner.${index}.id_issuer`)}
                        {renderField("Personal Email", owner.personal_email, `owner.${index}.personal_email`)}
                        {renderField("Personal Phone", owner.personal_phone, `owner.${index}.personal_phone`)}
                        {renderField("Address", owner.address, `owner.${index}.address`)}
                        {renderField("City", owner.city, `owner.${index}.city`)}
                        {renderField("State", owner.state, `owner.${index}.state`)}
                        {renderField("Country", owner.country, `owner.${index}.country`)}
                        {renderField("Postal Code", owner.postal_code, `owner.${index}.postal_code`)}
                        {renderField("Neighborhood", owner.neighborhood, `owner.${index}.neighborhood`)}
                        {renderField("Percentage", owner.percentage, `owner.${index}.percentage`)}
                        {renderField("Residency Time", owner.residency_time, `owner.${index}.residency_time`)}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                <SaveIcon />
              </Button>
            </Box>
          </Paper>
        </>
      )}
  
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Typography>Terminal has been successfully updated!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );  
};

TerminalDetails.propTypes = {
  terminalDetails: PropTypes.object.isRequired,
};

export default TerminalDetails;
