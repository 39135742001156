import { fetchData } from "../apiService";

const createAcquirerData = (formData, acquirerData) => {
    const data = {
      iso: formData.acquirer.iso || false,
      merchantHolding: formData.acquirer.merchantHolding || "",
      merchantHoldingID: formData.acquirer.merchantHoldingID || "",
      merchantGroup: formData.acquirer.merchantGroup || "",
      merchantGroupID: formData.acquirer.merchantGroupID || "",
      merchantName: formData.acquirer.merchantName || "",
      merchantStore: formData.acquirer.merchantStore || "",
      merchantAddress: formData.acquirer.merchantAddress || "",
      merchantNeighborhood: formData.acquirer.merchantNeighborhood || "",
      merchantCity: formData.acquirer.merchantCity || "",
      merchantCounty: formData.acquirer.merchantCounty || "Miami-Dade",
      merchantState: formData.acquirer.merchantState || "",
      merchantPostalCode: formData.acquirer.merchantPostalCode || "",
      merchantGeoLocation: formData.acquirer.merchantGeoLocation || "",
      mccID: formData.acquirer.mccID || "", 
      merchantID: formData.acquirer.merchantID || "",
      enableAmountOther: formData.acquirer.enableAmountOther || false,  
      timeStamp: new Date().toISOString(),
      //userToken: formData.acquirer.userToken || "LRQ1J/EYtNofP00ym3F0lSuLQL7SBzIVfKebzKGEl7A=",
      //userID: acquirerData.userID || "US01primo.operator@merchantpartners.com",
      terminal: {
        terminals: formData.acquirer.terminal.terminals.map((terminal) => ({
          terminalID: terminal.terminalID,
          terminalType: terminal.terminalType || "", 
          terminalSerial: terminal.terminalSerial,
        })),
      },
    };
  
    if (formData.acquirer.iso) {
      data.isoID = formData.acquirer.isoID || '';
    }
  
    return data;
  };
  
const createMerchantData = (formData) => ({
  //incorporationType: formData.merchant.incorporationType || "",
  incorporationDate: formData.merchant.incorporation_date ? formData.merchant.incorporation_date.replace(/-/g, "") : "",
  taxpayerID: formData.merchant.taxpayer_id || "",
  legalName: formData.merchant.legal_name || "",
  businessType: formData.merchant.business_type || "",
  businessPhone: formData.merchant.business_phone || "",
  businessEmail: formData.merchant.business_email || "",
  amountTxProcessing: formData.merchant.amount_tx_processing ? String(formData.merchant.amount_tx_processing) : "",
  amountTxProcessingTiers: formData.merchant.amount_tx_processing_tiers || "",
  percentTxProcessing: formData.merchant.percent_tx_processing ? String(formData.merchant.percent_tx_processing) : "",
  percentTxProcessingTiers: formData.merchant.percent_tx_processing_tiers || "",
  amountTerminalMaintenance: formData.merchant.amount_terminal_maintenance ? String(formData.merchant.amount_terminal_maintenance) : "",
  amountTerminalMaintenanceTiers: formData.merchant.amount_terminal_maintenance_tiers || "",
  terminalMaintenanceInactive: formData.merchant.terminal_maintenance_inactive || "False",
  amountTerminalSetup: formData.merchant.amount_terminal_setup ? String(formData.merchant.amount_terminal_setup) : "",
  amountTerminalSetupTiers: formData.merchant.amount_terminal_setup_tiers || "",
  effectiveDate: formData.merchant.effective_date ? formData.merchant.effective_date.replace(/-/g, "") : ""
});

const createOwnerData = (formData) => {
  if (Array.isArray(formData.owner) && formData.owner.length > 0) {
    return formData.owner.map(owner => ({
      percentage: Math.min(Math.max(parseInt(owner.percentage, 10) || 0, 0), 999).toString().padStart(3, "0"),
      firstName: owner.first_name || "",
      middleName: owner.middle_name || "",
      lastName: owner.last_name || "",
      taxpayerID: owner.taxpayer_id || "",
      ID: owner.id || "",
      idIssuer: owner.id_issuer || "",
      address: owner.address || "",
      neighborhood: owner.neighborhood || "",
      city: owner.city || "",
      county: owner.county || "",
      state: owner.state || "",
      postalCode: owner.postal_code || "",
      country: owner.country || "",
      residencyTime: owner.residency_time || "",
      personalPhone: owner.personal_phone || "",
      personalEmail: owner.personal_email || "",
    }));
  }
  return [];
};

const createBankData = (formData) => ({
  accountNumber: formData.bank.account_number || "",
  Iban: formData.bank.iban || "",
  swiftCode: formData.bank.swift_code || "",
  routingNumber: formData.bank.routing_number || "",
  branchNumber: formData.bank.branch_number || "",
  Bid: formData.bank.bid || "",
  address: formData.bank.address || "",
  city: formData.bank.city || "",
  state: formData.bank.state || "",
  postalCode: formData.bank.postal_code || "",
});

const createFiatData = (formData) => ({
  currencyCode: formData.currencyCode || "USD",
});

export const updateTerminal = async (formData, token, acquirerData) => {
  const endpoint = "/edit";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    acquirerName: acquirerData.acquirerName,
    acquirerID: acquirerData.acquirerId
  };

  const data = {
    version: "04.09.01",
    acquirer: createAcquirerData(formData, acquirerData),
    merchant: createMerchantData(formData),
    fiat: createFiatData(formData),
  };

  if (formData.owner) {
    data.owner = createOwnerData(formData);
  }
  
  if (formData.bank) {
    data.bank = createBankData(formData);
  }

  if (formData.owner) {
    data.owner = createOwnerData(formData);
  }
  
  try {
    const response = await fetchData({ endpoint, data, headers });
    return response;
  } catch (error) {
    console.error("Error updating terminal:", error);
    throw error;
  }
};
