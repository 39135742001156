import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import Dashboard from "./pages/DashboardPage";
import { AuthProvider } from "./context/AuthContext"; 

const theme = createTheme({
  typography: {
    fontFamily: "Arial",
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Switch>
            {/* redirect to dashboard if the route is / */}
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/dashboard" component={Dashboard} />
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
