import Keycloak from 'keycloak-js';

const config = {
    "url": process.env.REACT_APP_KEYCLOAK_URL,
    "realm": process.env.REACT_APP_KEYCLOAK_REALM,
    "clientId": process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    "redirect_uri": process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
  }

const keycloak = new Keycloak(config);

export default keycloak;